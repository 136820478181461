@font-face {
  font-family: Monofonto;
  font-style: normal;
  font-weight: normal;
  src: local("Monofonto"), url("./assets/fonts/monofonto.woff") format("woff");
}

* {
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

$dark: #020403;
$primary: #8bd692;

body {
  font-family: Monofonto;
  background: $dark;
  color: $primary;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
  background-image: url("./assets/images/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.wrapper {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0px 30px;
}

header {
  display: flex;
  padding-top: 50px;
}

hr {
  height: 7px;
  background-color: #a2ea9c;
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
}

.site-title {
  font-family: Monofonto;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(122, 189, 129, 0.7);
  margin-bottom: 25px;
}

.left,
.right {
  flex: 1;
}

.content {
  display: flex;
  justify-content: space-between;
  margin: 3em 0;
}

.activity {
  li {
    font-family: Monofonto;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    padding: 10px 0;
    list-style: none;
  }
}

.navigation {
  position: relative;
  li {
    list-style: none;
    padding: 5px 0;
  }
  li a {
    font-family: Monofonto;
    color: #8bd692;
    font-style: normal;
    text-decoration: none;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
  }
  &:before {
    content: url("./assets/images/left.png");
    // position: relative;
    // top: 35px;
    // right: 20px;
    position: absolute;
    top: -15px;
    left: -20px;
    z-index: -1;
  }
  &:after {
    content: url("./assets/images/right.png");
    // position: relative;
    // left: 40px;
    // bottom: 25px;
    position: absolute;
    left: 40px;
    bottom: -25px;
    z-index: -1;
  }
}

footer {
  // margin: 0 auto;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding-bottom: 50px;
  .button {
    width: 400px;
    height: 60px;
    margin: 0 auto;
    display: block;
    padding: 10px 57px;
    background: linear-gradient(
      90deg,
      rgba(56, 84, 59, 0.8) 0%,
      #3f6943 50%,
      rgba(56, 84, 59, 0.8) 100%
    );
    border: 4px solid #a2ea9c;
    box-sizing: border-box;
    font-size: 20px;
    line-height: 31px;
    color: #8bd692;
    font-family: Monofonto;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(162, 234, 156, 0.7);
  }

  .copyrights {
    text-align: center;
    margin-top: 50px;
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-filter: blur(8px);
    filter: blur(8px);
    border-radius: 5px;
  }
  33% {
    -webkit-transform: rotate(-0.5deg) translate(1px, -1px) scale(1.01);
    -ms-transform: rotate(-0.5deg) translate(1px, -1px) scale(1.01);
    transform: rotate(-0.5deg) translate(1px, -1px) scale(1.01);
    -webkit-filter: blur(10px);
    filter: blur(10px);
    border-radius: 3px;
  }
  67% {
    -webkit-transform: rotate(1deg) translate(-1px, -1px) scale(0.99);
    -ms-transform: rotate(1deg) translate(-1px, -1px) scale(0.99);
    transform: rotate(1deg) translate(-1px, -1px) scale(0.99);
    -webkit-filter: blur(14px);
    filter: blur(14px);
    border-radius: 7px;
  }
  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-filter: blur(8px);
    filter: blur(8px);
    border-radius: 5px;
  }
}

@media only screen and (max-width: 992px) {
  .site-title {
    font-size: 32px;
  }
  .activity li {
    font-size: 22px;
  }
}

@media only screen and (max-width: 600px) {
  body {
    background-position: center;
  }
  header {
    display: unset;
    text-align: center;
    padding-top: 50px;
  }
  .site-title {
    font-size: 18px;
    line-height: 22px;
  }

  .content {
    flex-direction: column-reverse;
  }

  .navigation {
    position: relative;
  }

  .navigation ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .navigation:after {
    left: 400px;
  }
  .navigation li a {
    margin-right: 10px;
  }
  .activity li {
    font-size: 22px;
  }
}

@media only screen and (max-width: 425px) {
  .navigation:after {
    left: 310px;
    bottom: 0px;
  }
  .navigation:before {
    left: -10px;
  }
}

@media only screen and (max-width: 375px) {
  .navigation:after {
    left: 280px;
  }
}
@media only screen and (max-width: 320px) {
  .navigation:after {
    left: 230px;
  }
}
